import React, { useState } from "react";
import { MdOutlineMailOutline } from "react-icons/md";
import { HiOutlineLocationMarker } from "react-icons/hi";
import axios from "axios";
import ReCAPTCHA from "react-google-recaptcha";
import { Modal } from "react-bootstrap";
import "./modalContent.css";

export default function Contact() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    const formData = {
      name,
      email,
      subject,
      message,
    };

    try {
      const response = await axios.post(
        `https://sattraders.co.uk/api_sattraders/send_email.php`,
        formData
      );
      setShowModal(true);
      console.log("response.data", response.data);
      setName("");
      setEmail("");
      setSubject("");
      setMessage("");
    } catch (error) {
      console.error("Failed to send email:", error);
    } finally {
      setLoading(false);
    }

    console.log(formData);
  };

  const handleContinue = () => {
    setShowModal(false);
  };

  return (
    <div className="d-block d-md-flex contact_form_div my-0 my-md-3 pb-5 pb-md-0 mb-4 mb-md-0">
      <div className="col-12 col-md-5 contact_form_icons_div ">
        <div className="d-flex justify-content-center align-items-center h1__contact">
          <h1 className="d-block d-md-none text-center pt-5 pb-3">
            Contact Us
          </h1>
        </div>
        <div className="d-flex align-items-center  py-4">
          <div className="contact_icon_div me-3">
            <MdOutlineMailOutline className="icon___" />
          </div>

          <div className="content_form">
            <h1>Email</h1>
            <a href="mailto:info@sattraders.co.uk">info@sattraders.co.uk</a>
          </div>
        </div>

        <div className="d-flex align-items-center py-2 py-md-4">
          <div className="contact_icon_div me-3">
            <HiOutlineLocationMarker className="icon___" />
          </div>

          <div className="content_form">
            <h1>Address</h1>
            <a
              href="https://maps.app.goo.gl/kbRkCPrpW8AoUgrC6"
              target="_blank"
              rel="noopener noreferrer"
            >
              19a-20 Llewellyn Street, Pentre, Mid Glamorgan, United Kingdom,
              CF41 7BX
            </a>
          </div>
        </div>
      </div>

      <div className="col-12 col-md-7 contact_form_manin_div__">
        <h1 className="pb-4 ms-3 d-none d-md-block">Contact Us</h1>
        <form onSubmit={handleSubmit}>
          <div className="d-block d-md-flex justify-content-between align-items-center">
            <div className="mb-3 custom_col">
              <label htmlFor="name" className="form-label">
                Name
              </label>
              <input
                type="text"
                className="form-control"
                id="name"
                name="name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                required
              />
            </div>

            <div className="mb-3 custom_col ">
              <label htmlFor="email" className="form-label">
                Email
              </label>
              <input
                type="email"
                className="form-control"
                id="email"
                name="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </div>
          </div>

          <div className="mb-3">
            <label htmlFor="subject" className="form-label">
              Subject
            </label>
            <input
              type="text"
              className="form-control"
              id="subject"
              name="subject"
              value={subject}
              onChange={(e) => setSubject(e.target.value)}
              required
            />
          </div>

          <div className="mb-3">
            <label htmlFor="message" className="form-label">
              Message
            </label>
            <textarea
              className="form-control"
              id="message"
              name="message"
              rows="4"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              required
            ></textarea>
          </div>
          <div className="d-block d-md-flex justify-content-between align-items-center">
            <div className="pb-4">
              <button
                type="submit"
                className="contact_btn_div col-12 "
                disabled={loading}
              >
                {loading ? "Sending..." : "Send Message"}
              </button>
            </div>
          </div>
        </form>
      </div>

      <Modal
        show={showModal}
        onHide={() => setShowModal(false)}
        className="modal_main"
      >
        <h2 className="text-center pt-4 box_content_heading">Message sent!</h2>
        <Modal.Body>
          <div className="box_content px-2">
            {/* <div  className="d-flex col-12 align-items-center justify-content-center"> <img src={celebration} style={{width: "110px", height: "110px"}} alt="Celebration"/></div> */}
            <p className="phra">
              Thank you for reaching out to us! We understand your time is
              valuable, and we promise to get back to you within 1-2 business
              days.
            </p>
          </div>
        </Modal.Body>
        <div className="px-4 pt-3 pb-4">
          <button className="col-12 box_btn" onClick={handleContinue}>
            Done
          </button>
        </div>
      </Modal>
    </div>
  );
}
